import React, {createRef, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import moment from 'moment';
import {isEqual} from 'lodash';
import config from '../config.js';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/EADashboard.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';
import Spinner from '../components/common/Spinner.js';
import PeriodicBudgetUtilizationChart from '../components/EADashboard/PeriodicBudgetUtilizationChart.js';
import VariableBudgetUtilizationChart  from '../components/EADashboard/VariableBudgetUtilizationChart.js';
import HostUtilizationChart from '../components/EADashboard/HostUtilizationChart.js';
import ProductUtilizationChart from '../components/EADashboard/ProductUtilizationChart.js';
import Alert from '../components/common/Alert.js';
import {capitalizeSentence} from '../components/common/utilities.js';

// kendo react
import {Card, CardTitle, CardBody} from '@progress/kendo-react-layout';
import {Label} from '@progress/kendo-react-labels';
import {DropDownList, MultiSelect} from '@progress/kendo-react-dropdowns';
import {InputPrefix} from '@progress/kendo-react-inputs';
import {DatePicker} from '@progress/kendo-react-dateinputs';
import {Button} from '@progress/kendo-react-buttons';
import {Tooltip} from '@progress/kendo-react-tooltip';
import {Chart} from '@progress/kendo-react-charts';
import {ExcelExport, ExcelExportColumn} from '@progress/kendo-react-excel-export';
import {filterBy} from '@progress/kendo-data-query';
import {userIcon, fileExcelIcon} from '@progress/kendo-svg-icons';

// multilingual
import {Text} from '../components/common/MultilingualText.js';
import {useLocalization} from '@progress/kendo-react-intl';
import {
    eADashboardKey,
    selectEATitleKey,
    selectEAMessageKey,
    filtersKey,
    budgetUtilizationOverviewKey,
    eANameKey,
    userKey,
    yearKey,
    allUsersKey,
    noDataAvailableKey,
    userSelectedKey,
    usersSelectedKey,
    allUsersSelectedKey,
    totalCostKey,
    totalBudgetVsUtilizedBudgetKey,
    budgetUtilizationDetailsByPeriodKey,
    periodKey,
    exportAllExcelKey,
    budgetUtilizationDetailsByHostKey,
    top10ProductsSpentKey,
    eAIDKey,
    customerNameKey,
    subsYearKey,
    eAPeriodIDKey,
    eAPeriodStartDateKey,
    eAPeriodEndDateKey,
    totalSubscriptionPoolKey,
    consumedSubscriptionPoolKey,
    percentageUsedKey,
    productNumberKey,
    productDescriptionKey,
    priceKey,
    qtyKey,
    hostKey,
    startDateKey,
    endDateKey,
    licenseTypeKey,
    dataRequestorInformationKey,
    mainMessages
} from '../assets/text/MultilingualText.js';

// ea consts
const initialEA = {
    name: {},
    user: [],
    period: {},
    year: null
};
const chartColors = ["#30578D", "#F2A945", "#468657"];

function EADashboard(props) {
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [eA, setEA] = useState(initialEA);
    const [eANames, setEANames] = useState([]);
    const [users, setUsers] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [years, setYears] = useState([]);

    // users filter
    const [filter, setFilter] = useState([]);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const [dateRange, setDateRange] = useState({
        start: today,
        end: today,
        max: today,
        min: today
    });

    const [subscriptionPool, setSubscriptionPool] = useState([]);
    // filtered subscription pool
    const [pool, setPool] = useState([]);

    const [currency, setCurrency] = useState("");

    // kendo react excel export - budget overview
    const [budgetOverviewExcelData, setBudgetOverviewExcelData] = useState([]);
    const [currentYearProductsExcelData, setCurrentYearProductsExcelData] = useState([]);
    const [currentPeriodProductsExcelData, setCurrentPeriodProductsExcelData] = useState([]);
    const _budgetOverviewExporter = createRef();
    const _currentYearProductsExporter = createRef();
    const _currentPeriodProductsExporter = createRef();
    const exportBudgetOverviewExcel = () => {
        let budgetOverviewSheet = _budgetOverviewExporter.current.workbookOptions();
        // ea id and customer name table
        const rows = budgetOverviewSheet.sheets[0].rows;
        let style = {
            background: "#7a7a7a",
            color: "#fff"
        };
        let headerCells = [
            {
                ...style,
                value: localization.toLanguageString(eAIDKey, mainMessages[siteLanguageDefault][eAIDKey])
            },
            {
                ...style,
                value: localization.toLanguageString(customerNameKey, mainMessages[siteLanguageDefault][customerNameKey])
            },
        ];
        let dataCells = [
            {value: eA?.name?.id},
            {value: eA?.name?.customer_name},
        ]
        let header = [
            {
                type: 'header',
                cells: headerCells
            },
            {
                type: 'data',
                cells: dataCells
            },
            {}
        ]
        rows.unshift(...header);

        let currentYearProductsSheet = _currentYearProductsExporter.current.workbookOptions();
        let currentPeriodProductsSheet = _currentPeriodProductsExporter.current.workbookOptions();

        budgetOverviewSheet.sheets[0].name = 'Budget Utilization Overview';
        currentYearProductsSheet.sheets[0].name = 'Year ' + eA.year + ' Products';
        currentPeriodProductsSheet.sheets[0].name = 'Period ' + eA.period.period + ' Products';

        budgetOverviewSheet.sheets = budgetOverviewSheet.sheets
            .concat(currentYearProductsSheet.sheets)
            .concat(currentPeriodProductsSheet.sheets);

        _budgetOverviewExporter.current.save(budgetOverviewSheet);
    };

    // kendo react excel export - budget period
    const [budgetPeriodExcelData, setBudgetPeriodExcelData] = useState([]);
    const [budgetPeriodExcelFileName, setBudgetPeriodExcelFileName] = useState("");
    const [disableExcelExport, setDisableExcelExport] = useState({
        overview: true,
        period: true
    });
    const _budgetPeriodExporter = createRef();
    const exportBudgetPeriodExcel = () => {
        let budgetPeriodSheet = _budgetPeriodExporter.current.workbookOptions();
        // ea id and customer name table
        const rows = budgetPeriodSheet.sheets[0].rows;
        let style = {
            background: "#7a7a7a",
            color: "#fff"
        };
        let headerCells = [
            {
                ...style,
                value: localization.toLanguageString(eAIDKey, mainMessages[siteLanguageDefault][eAIDKey])
            },
            {
                ...style,
                value: localization.toLanguageString(customerNameKey, mainMessages[siteLanguageDefault][customerNameKey])
            },
        ];
        let dataCells = [
            {value: eA?.name?.id},
            {value: eA?.name?.customer_name},
        ]
        let header = [
            {
                type: 'header',
                cells: headerCells
            },
            {
                type: 'data',
                cells: dataCells
            },
            {}
        ]
        rows.unshift(...header);

        _budgetPeriodExporter.current.save(budgetPeriodSheet);
    };

    const [isLoading, setIsLoading] = useState(false);

    const INFO_ALERT = {
        type: 'info',
        title: localization.toLanguageString(selectEATitleKey, mainMessages[siteLanguageDefault][selectEATitleKey]),
        message: localization.toLanguageString(selectEAMessageKey, mainMessages[siteLanguageDefault][selectEAMessageKey])
    };
    const [alerts, setAlerts] = useState([INFO_ALERT]);

    const ALL_USERS_KEY = localization.toLanguageString(allUsersKey, mainMessages[siteLanguageDefault][allUsersKey]);

    const AXIS_LABELS = {
        font: '14px Graphik LG Web, serif',
        rotation: 'auto'
    };

    // no data overlay
    const NoDataOverlay = (props) => {
        let style = {
            ...props.style,
            alignContent: "center",
            textAlign: "center"
        };
        return (
            <div style={style}>
                {localization.toLanguageString(noDataAvailableKey, mainMessages[siteLanguageDefault][noDataAvailableKey])}
            </div>
        )
    }

    // users tooltip
    const UsersTooltip = () => <>
        {eA.user.filter(user => user !== ALL_USERS_KEY).map(user => <>{user}<br/></>)}
    </>;

    // users prefix
    const UsersPrefix = () => <InputPrefix orientation="vertical">
        <Button
            fillMode={'flat'}
            svgIcon={userIcon}
        />
    </InputPrefix>

    // users multiselect
    const UsersMultiSelect = () => <MultiSelect
        className={'ksm-dropdownlist'}
        value={eA.user}
        data={users}
        field="user"
        filterable={true}
        prefix={UsersPrefix}
        tags={eA.user.includes(ALL_USERS_KEY) ? [{
            text: localization.toLanguageString(allUsersSelectedKey, mainMessages[siteLanguageDefault][allUsersSelectedKey]),
            data: [...eA.user]
        }] : [{
            text: eA.user.length === 1 ? '1 ' + localization.toLanguageString(userSelectedKey, mainMessages[siteLanguageDefault][userSelectedKey])
                : eA.user.length + ' ' + localization.toLanguageString(usersSelectedKey, mainMessages[siteLanguageDefault][usersSelectedKey]),
            data: [...eA.user]
        }]}
        onChange={onEAChange}
        onFilterChange={onFilterChange}
    />

    // ea name render
    const eANameRender = (li, props) => {
        let name = props.dataItem;
        const itemChildren = <span>
            <Row>
                <Col className={"ea-dashboard-no-wrap pe-0"}><b>{name.customer_name}</b></Col>
                <Col className={"ea-dashboard-no-wrap ps-1"}>| {capitalizeSentence(name.remix_type)}</Col>
            </Row>
            <Row className={"ps-1"}>
                <Col className={"ea-dashboard-no-wrap pe-0"}>{moment(name.start_date).format('ll')} to {moment(name.end_date).format('ll')}</Col>
                <Col className={"ea-dashboard-no-wrap ps-1"}>| {capitalizeSentence(name.status)}</Col>
            </Row>
        </span>;
        return React.cloneElement(li, li.props, itemChildren);
    };

    /*
     * onEAChange() updates the EA object on change
     * @param {e} the input change event
    */
    const onEAChange = (e) => {
        let field = e.target.name || e.target.props.field;

        // handle select all users
        if (field === 'user') {
            let value = e.value;
            let difference = eA.user.filter(u => !e.value.includes(u));
            let compliment = e.value.filter(u => !eA.user.includes(u));

            // case: deselect all
            if (difference.includes(ALL_USERS_KEY)) {
                value = [];
            }
            // case: select all
            else if (compliment.includes(ALL_USERS_KEY)) {
                value = users;
            }
            // case: deselect a single user
            else if (difference.length > compliment.length) {
                value = value.filter(u => u !== ALL_USERS_KEY);
            }
            // case: select all users individually
            else if (isEqual(
                [...eA.user, ...compliment],
                [...value]
                ) && value.length === users.length - 1) {
                value = users;
            }

            setEA(eA => ({
                ...eA,
                [field]: value
            }));
        } else {
            // todo: next year chart
            setEA(eA => ({
                ...eA,
                [field]: e.value
            }));
        }
    }

    /*
     * onDateRangeChange() updates the date range object on change
     * @param {e} the input change event
    */
    const onDateRangeChange = (e) => {
        let field = e.target.props.field;
        setDateRange(dateRange => ({
            ...dateRange,
            [field]: e.value
        }));
    }

    /*
     * onFilterChange() filters the users search
     * @param {e} the input change event
    */
    const onFilterChange = (e) => {
        setUsers(filterBy(filter.slice(), e.filter));
    };

    /*
     * showHandler() handler for all alerts
    */
    const showHandler = (index) => setAlerts(alerts.filter((a, i) => i !== index));

    /*
     * getEA() gets all EA dropdown options for the ea name, user, and period dropdowns
    */
    function getEA() {
        let headers = {
            'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken
        };

        setIsLoading(true);

        axios.get(
            config.ea_dashboard.EA,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data || [];
                    let names = data.map(d => ({
                        id: d.ea_id,
                        customer_name: d.customer_name,
                        remix_type: d.remix_type,
                        start_date: d.start_date,
                        end_date: d.end_date,
                        status: d.status,
                        name: d.customer_name
                            + " | " + capitalizeSentence(d.remix_type)
                            + " | " + moment(d.start_date).format('ll') + " to " + moment(d.end_date).format('ll')
                            + " | " + capitalizeSentence(d.status)
                    }));

                    setEANames(names);
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET EA", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    /*
     * getEABudget() gets all EA budget data
    */
    function getEABudget() {
        let headers = {
            'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken
        };

        setIsLoading(true);

        axios.get(
            config.ea_dashboard.EA,
            {
                headers: headers,
                params: {ea: eA.name.id}
            }
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data || [];
                    let periods = [];
                    let subscriptionPool = data
                        .map(d => {
                            let eAID = eA.name.id;
                            let startDate = d.period_start_date && moment(d.period_start_date).isValid() ? new Date(d.period_start_date) : null;
                            let endDate = d.period_end_date && moment(d.period_end_date).isValid() ? new Date(d.period_end_date) : null;
                            let consumed = d.redeemed_subscription_pool;
                            let percent = consumed / d.total_subscription_pool;
                            let products = d.values
                                .map(p => ({
                                    ...p,
                                    ea_id: eAID,
                                    start_date: new Date(p.license_start_date),
                                    end_date: new Date(p.license_end_date),
                                    ea_period_id: d.ea_period_id,
                                    period_start_date: startDate,
                                    period_end_date: endDate
                                }))
                                .sort((a, b) => a - b);
                            let period = {
                                period: d.ea_period_id,
                                period_date: "Period " + d.ea_period_id + " (" + moment(startDate).format('ll') + " - " + moment(endDate).format('ll') + ")"
                            };

                            periods.push(period);

                            return {
                                ...period,
                                year: d.subs_year,
                                start_date: startDate,
                                end_date: endDate,
                                consumed: consumed,
                                total: d.total_subscription_pool,
                                percent: percent,
                                currency: d.currency_code,
                                products: products
                            }
                        })
                        .sort((a, b) => a.year - b.year)
                        .sort((a, b) => a.period - b.period);

                    periods.sort((a, b) => a.period - b.period);

                    setSubscriptionPool(subscriptionPool);
                    setPool(subscriptionPool);
                    setPeriods(periods);
                    setCurrency(subscriptionPool[0].currency);
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET EA Budget", error);

                setSubscriptionPool([]);
                setPool([]);
                setPeriods([]);

                // generic error message
                let alert = {
                    type: 'error',
                    title: localization.toLanguageString(noDataAvailableKey, mainMessages[siteLanguageDefault][noDataAvailableKey])
                };
                setAlerts(alerts => [...alerts, alert]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    // 1. on load - initial page load
    useEffect(() => {
        getEA();
    }, []);

    // 2. on load - get budget utilization on ea name change
    useEffect(() => {
        if (eA.name.id) {
            getEABudget();
        }
    }, [eA.name]);

    // 3. on load - set default users, years, current year, current period, and date range (variable) on subscription pool change
    useEffect(() => {
        setAlerts([]);
        if (subscriptionPool.length && periods.length) {
            const today = new Date();
            let currentPool = subscriptionPool.find((pool) => {
                const startDate = new Date(pool.start_date);
                const endDate = new Date(pool.end_date);
                return today >= startDate && today <= endDate;
            });

            // handle no current pool
            if (!currentPool) {
                currentPool = subscriptionPool.reduce((max, pool) => pool.period > max.period ? pool : max, subscriptionPool[0]);
            }

            // set users
            let productUsers = currentPool.products
                .filter(p => p.requested_by)
                .map(p => p.requested_by);
            let allUsers = [...new Set([ALL_USERS_KEY, ...productUsers])];
            setFilter(allUsers.slice());
            setUsers(allUsers);

            // set years
            setYears([...new Set(subscriptionPool.map(p => p.year))].sort());

            // set all users (default), current year, and current period
            setEA(eA => ({
                ...eA,
                user: allUsers,
                year: currentPool.year,
                period: periods.find(p => p.period === currentPool.period)
            }));

            // set date range
            if (eA.name?.remix_type?.toUpperCase() === "VARIABLE") {
                setDateRange(dateRange => ({
                    ...dateRange,
                    start: currentPool.start_date,
                    end: currentPool.end_date,
                    min: currentPool.start_date,
                    max: currentPool.end_date
                }));
            }
        }
    }, [subscriptionPool, periods]);

    // user - filter pool data, users, and date range (variable) dropdown on year change
    useEffect(() => {
        setAlerts([]);
        let yearPool = subscriptionPool.filter(p => p.year === eA.year);
        if (yearPool.length) {
            // filter subscription pool by year
            setPool(yearPool);

            // set users by year
            let poolProducts = [].concat(...yearPool.map(p => p.products));
            let productUsers = poolProducts
                .filter(p => p.requested_by)
                .map(p => p.requested_by);
            let allUsers = [...new Set([ALL_USERS_KEY, ...productUsers])];
            setFilter(allUsers.slice());
            setUsers(allUsers);
            setEA(eA => ({
                ...eA,
                user: allUsers
            }));

            // set date range by year and period
            if (eA.name?.remix_type?.toUpperCase() === "VARIABLE") {
                let pool = subscriptionPool.find(p => p.year === eA.year);
                setDateRange(dateRange => ({
                    ...dateRange,
                    start: pool.start_date,
                    end: pool.end_date,
                    min: pool.start_date,
                    max: pool.end_date
                }));

                setEA(eA => ({
                    ...eA,
                    period: periods.find(p => p.period === pool.period)
                }));
            }
        }
    }, [eA.year]);

    // user - update products and consumed subscription pool on user selection
    // note: filters on current year
    useEffect(() => {
        // case: all users
        if (eA.user.includes(ALL_USERS_KEY)) {
            let yearPool = subscriptionPool.filter(p => p.year === eA.year);
            setPool(yearPool);
        }
        // case: selected users
        else if (eA.user.length && !eA.user.includes(ALL_USERS_KEY)) {
            const updates = pool.map(p => {
                const filtered = p.products.filter(p => eA.user.includes(p.requested_by));
                const consumed = filtered.reduce((accumulator, product) => accumulator + product.total_cost, 0);
                const percent = consumed / p.total;
                return {
                    ...p,
                    products: filtered,
                    consumed: consumed,
                    percent: percent
                }
            });

            // show error for no data on selected users (data available after next period)
            if (!updates.length) {
                let alert = {
                    type: 'error',
                    title: localization.toLanguageString(noDataAvailableKey, mainMessages[siteLanguageDefault][noDataAvailableKey]),
                    message: localization.toLanguageString(dataRequestorInformationKey, mainMessages[siteLanguageDefault][dataRequestorInformationKey])
                };
                setAlerts(alerts => [...alerts, alert]);
            }

            setPool(updates);
        }
    }, [eA.user]);

    // set info alert when no EA is selected
    useEffect(() => {
        !eA?.name?.name ? setAlerts([INFO_ALERT]) : setAlerts([]);
    }, [eA.name]);

    // set to all users by default and on clear
    useEffect(() => {
        if (eA.user.length === 0 && users.length) {
            setEA(eA => ({...eA, user: users}));
        }
    }, [eA.user]);

    // excel export - budget overview
    useEffect(() => {
        if (eA.year && eA.period) {
            let budgetOverview = [];
            let currentYearProducts = [];
            let currentPeriodProducts = [];

            // set excel data for budget overview
            budgetOverview = subscriptionPool.map(pool => ({
                ea_period_id: pool.period,
                period_start_date: pool.start_date,
                period_end_date: pool.end_date,
                subs_year: pool.year,
                total_subscription_pool: pool.total,
                redeemed_subscription_pool: pool.consumed,
                percent: pool.percent * 100
            }));
            setBudgetOverviewExcelData(budgetOverview);

            // set excel data for products in current year
            const CURRENT_YEAR_POOL = subscriptionPool.filter(p => p.year === eA.year);
            CURRENT_YEAR_POOL.forEach(pool => {
                currentYearProducts = [...currentYearProducts, ...pool.products];
            });
            setCurrentYearProductsExcelData(currentYearProducts);

            // set excel data for products in current period
            const CURRENT_PERIOD_POOL = subscriptionPool.filter(p => p.period === eA.period.period);
            CURRENT_PERIOD_POOL.forEach(pool => {
                currentPeriodProducts = [...currentPeriodProducts, ...pool.products];
            });
            setCurrentPeriodProductsExcelData(currentPeriodProducts);

            // enable excel export
            setDisableExcelExport(disableExcelExport => ({
                ...disableExcelExport,
                overview: false
            }));
        }
    }, [eA.year, eA.period]);

    // excel export - budget period
    useEffect(() => {
        let pool = [];
        let subscriptionPoolProducts = [];
        // get pool based on year and filter products on date range for variable ea's
        if (eA.name?.remix_type?.toUpperCase() === "VARIABLE") {
            pool = subscriptionPool.find(p => p.year === eA.year);
            subscriptionPoolProducts = pool.products.filter(p => new Date(p.license_start_date) >= dateRange.start && new Date(p.license_end_date) <= dateRange.end) || [];

            // set excel file name
            let startDate = moment(dateRange.start).format('MM_DD_YYYY');
            let endDate = moment(dateRange.end).format('MM_DD_YYYY');
            setBudgetPeriodExcelFileName("KSM_BUDGET_UTILIZATION_YEAR_" + eA.year + "_" + startDate + "_" + endDate + ".xlsx");
        }
        // get pool based on period for periodic ea's
        else {
            pool = subscriptionPool.find(p => p.period === eA.period.period);
            subscriptionPoolProducts = pool?.products || [];

            // set excel file name
            setBudgetPeriodExcelFileName("KSM_BUDGET_UTILIZATION_YEAR_" + eA.year + "_PERIOD_" + eA.period.period + ".xlsx");
        }

        // set excel data
        setBudgetPeriodExcelData(subscriptionPoolProducts);
        subscriptionPoolProducts.length ? setDisableExcelExport(disableExcelExport => ({
            ...disableExcelExport,
            period: false
        })) : setDisableExcelExport(disableExcelExport => ({
            ...disableExcelExport,
            period: true
        }));
    }, [eA.period, dateRange]);

    useEffect(() => {
        console.log("EA", eA);
    }, [eA]);

    useEffect(() => {
        console.log("users", users);
    }, [users]);

    useEffect(() => {
        console.log("subscription pool", subscriptionPool);
    }, [subscriptionPool]);

    useEffect(() => {
        console.log("pool", pool);
    }, [pool]);

    useEffect(() => {
        let range = {}
        Object.keys(dateRange).forEach(key => {
            range[key] = moment(dateRange[key]).format('ll');
        });
        console.log("dateRange", range);
    }, [dateRange]);

    return (
        <>
            <PageHeader/>
            {isLoading ? <Spinner/> : <></>}
            <div className={"ksm-page-container"}>
                <div className={"k-h4"}>
                    {localization.toLanguageString(eADashboardKey, mainMessages[siteLanguageDefault][eADashboardKey])}
                </div>
                {!!alerts.length && <Row className={"mt-4"}>
                    <Col>
                        {alerts.map((alert, index) => <>
                            {alert.type === 'error' ? <Alert
                                className={"my-3 p-0"}
                                key={'error-' + index}
                                type={'error'}
                                title={alert.title}
                                message={alert.message}
                                showHandler={() => showHandler(index)}
                            /> : alert.type === 'success' ? <Alert
                                className={"my-3 p-0"}
                                key={'success-' + index}
                                type={'success'}
                                title={alert.title}
                                message={alert.message}
                                showHandler={() => showHandler(index)}
                            /> : alert.type === 'info' ? <Alert
                                className={"my-3 p-0"}
                                key={'info-' + index}
                                type={'info'}
                                title={alert.title}
                                message={alert.message}
                                showHandler={() => showHandler(index)}
                            /> : <></>}
                        </>)}
                    </Col>
                </Row>}
                <Row className={"mt-4 mb-2"}>
                    <Col className={"d-flex align-items-center"}>
                        <div className={"k-h5 mb-0"}>
                            {localization.toLanguageString(filtersKey, mainMessages[siteLanguageDefault][filtersKey])}
                        </div>
                    </Col>
                </Row>
                <Row className={"my-2"}>
                    <Col
                        xs={4}
                        className={"pe-1"}
                    >
                        <Label>
                            {localization.toLanguageString(eANameKey, mainMessages[siteLanguageDefault][eANameKey])}
                        </Label>
                        <Tooltip
                            anchorElement="target"
                            showCallout={true}
                            parentTitle={true}
                            openDelay={0}
                            position="top"
                        >
                            <DropDownList
                                title={eA.name.name}
                                className={'ksm-dropdownlist'}
                                value={eA.name}
                                data={eANames}
                                disabled={!eANames.length}
                                textField={'name'}
                                field="name"
                                itemRender={eANameRender}
                                onChange={onEAChange}
                            />
                        </Tooltip>
                    </Col>
                    <Col
                        xs={2}
                        className={"ps-1"}
                    >
                        <Label>
                            {localization.toLanguageString(userKey, mainMessages[siteLanguageDefault][userKey])}
                        </Label>
                        {eA.user.filter(u => u !== ALL_USERS_KEY).length ? <Tooltip
                            anchorElement="target"
                            showCallout={true}
                            parentTitle={true}
                            openDelay={0}
                            position="bottom"
                            content={UsersTooltip}
                        >
                            <span title={eA.user}>
                                <UsersMultiSelect/>
                            </span>
                        </Tooltip> : <UsersMultiSelect/>}
                    </Col>
                    <Col
                        xs={1}
                        className={"ps-1"}
                    >
                        <Label>
                            {localization.toLanguageString(yearKey, mainMessages[siteLanguageDefault][yearKey])}
                        </Label>
                        <DropDownList
                            value={eA.year}
                            data={years}
                            disabled={!years.length}
                            field="year"
                            onChange={onEAChange}
                        />
                    </Col>
                    <Col className={"d-flex align-items-end justify-content-end"}>
                        <Tooltip
                            anchorElement="target"
                            showCallout={true}
                            parentTitle={true}
                            openDelay={0}
                            position="left"
                        >
                            <Button
                                title={localization.toLanguageString(exportAllExcelKey, mainMessages[siteLanguageDefault][exportAllExcelKey])}
                                themeColor={"tertiary"}
                                fillMode={"solid"}
                                svgIcon={fileExcelIcon}
                                disabled={disableExcelExport.overview}
                                onClick={exportBudgetOverviewExcel}
                            />
                        </Tooltip>
                        <ExcelExport
                            data={budgetOverviewExcelData}
                            ref={_budgetOverviewExporter}
                            fileName={"KSM_BUDGET_UTILIZATION.xlsx"}
                            onExportComplete={() => setIsLoading(false)}
                        >
                            <ExcelExportColumn
                                field="subs_year"
                                title={localization.toLanguageString(subsYearKey, mainMessages[siteLanguageDefault][subsYearKey])}
                            />
                            <ExcelExportColumn
                                field="ea_period_id"
                                title={localization.toLanguageString(eAPeriodIDKey, mainMessages[siteLanguageDefault][eAPeriodIDKey])}
                            />
                            <ExcelExportColumn
                                field="period_start_date"
                                title={localization.toLanguageString(eAPeriodStartDateKey, mainMessages[siteLanguageDefault][eAPeriodStartDateKey])}
                            />
                            <ExcelExportColumn
                                field="period_end_date"
                                title={localization.toLanguageString(eAPeriodEndDateKey, mainMessages[siteLanguageDefault][eAPeriodEndDateKey])}
                            />
                            <ExcelExportColumn
                                field="total_subscription_pool"
                                title={localization.toLanguageString(totalSubscriptionPoolKey, mainMessages[siteLanguageDefault][totalSubscriptionPoolKey])}
                            />
                            <ExcelExportColumn
                                field="redeemed_subscription_pool"
                                title={localization.toLanguageString(consumedSubscriptionPoolKey, mainMessages[siteLanguageDefault][consumedSubscriptionPoolKey])}
                            />
                            <ExcelExportColumn
                                field="percent"
                                title={localization.toLanguageString(percentageUsedKey, mainMessages[siteLanguageDefault][percentageUsedKey])}
                            />
                        </ExcelExport>
                        <ExcelExport
                            data={currentYearProductsExcelData}
                            ref={_currentYearProductsExporter}
                            fileName={"KSM_BUDGET_UTILIZATION.xlsx"}
                        >
                            <ExcelExportColumn
                                field="ea_period_id"
                                title={localization.toLanguageString(eAPeriodIDKey, mainMessages[siteLanguageDefault][eAPeriodIDKey])}
                            />
                            <ExcelExportColumn
                                field="period_start_date"
                                title={localization.toLanguageString(eAPeriodStartDateKey, mainMessages[siteLanguageDefault][eAPeriodStartDateKey])}
                            />
                            <ExcelExportColumn
                                field="period_end_date"
                                title={localization.toLanguageString(eAPeriodEndDateKey, mainMessages[siteLanguageDefault][eAPeriodEndDateKey])}
                            />
                            <ExcelExportColumn
                                field="ea_id"
                                title={localization.toLanguageString(eAIDKey, mainMessages[siteLanguageDefault][eAIDKey])}
                            />
                            <ExcelExportColumn
                                field="prod_num"
                                title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                            />
                            <ExcelExportColumn
                                field="product_description"
                                title={localization.toLanguageString(productDescriptionKey, mainMessages[siteLanguageDefault][productDescriptionKey])}
                            />
                            <ExcelExportColumn
                                field="license_type"
                                title={localization.toLanguageString(licenseTypeKey, mainMessages[siteLanguageDefault][licenseTypeKey])}
                            />
                            <ExcelExportColumn
                                field="price"
                                title={localization.toLanguageString(priceKey, mainMessages[siteLanguageDefault][priceKey])}
                            />
                            <ExcelExportColumn
                                field="quantity"
                                title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                            />
                            <ExcelExportColumn
                                field="total_cost"
                                title={localization.toLanguageString(totalCostKey, mainMessages[siteLanguageDefault][totalCostKey])}
                            />
                            <ExcelExportColumn
                                field="host_id"
                                title={localization.toLanguageString(hostKey, mainMessages[siteLanguageDefault][hostKey])}
                            />
                            <ExcelExportColumn
                                field="start_date"
                                title={localization.toLanguageString(startDateKey, mainMessages[siteLanguageDefault][startDateKey])}
                            />
                            <ExcelExportColumn
                                field="end_date"
                                title={localization.toLanguageString(endDateKey, mainMessages[siteLanguageDefault][endDateKey])}
                            />
                        </ExcelExport>
                        <ExcelExport
                            data={currentPeriodProductsExcelData}
                            ref={_currentPeriodProductsExporter}
                            fileName={"KSM_BUDGET_UTILIZATION.xlsx"}
                        >
                            <ExcelExportColumn
                                field="ea_period_id"
                                title={localization.toLanguageString(eAPeriodIDKey, mainMessages[siteLanguageDefault][eAPeriodIDKey])}
                            />
                            <ExcelExportColumn
                                field="period_start_date"
                                title={localization.toLanguageString(eAPeriodStartDateKey, mainMessages[siteLanguageDefault][eAPeriodStartDateKey])}
                            />
                            <ExcelExportColumn
                                field="period_end_date"
                                title={localization.toLanguageString(eAPeriodEndDateKey, mainMessages[siteLanguageDefault][eAPeriodEndDateKey])}
                            />
                            <ExcelExportColumn
                                field="ea_id"
                                title={localization.toLanguageString(eAIDKey, mainMessages[siteLanguageDefault][eAIDKey])}
                            />
                            <ExcelExportColumn
                                field="prod_num"
                                title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                            />
                            <ExcelExportColumn
                                field="product_description"
                                title={localization.toLanguageString(productDescriptionKey, mainMessages[siteLanguageDefault][productDescriptionKey])}
                            />
                            <ExcelExportColumn
                                field="license_type"
                                title={localization.toLanguageString(licenseTypeKey, mainMessages[siteLanguageDefault][licenseTypeKey])}
                            />
                            <ExcelExportColumn
                                field="price"
                                title={localization.toLanguageString(priceKey, mainMessages[siteLanguageDefault][priceKey])}
                            />
                            <ExcelExportColumn
                                field="quantity"
                                title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                            />
                            <ExcelExportColumn
                                field="total_cost"
                                title={localization.toLanguageString(totalCostKey, mainMessages[siteLanguageDefault][totalCostKey])}
                            />
                            <ExcelExportColumn
                                field="host_id"
                                title={localization.toLanguageString(hostKey, mainMessages[siteLanguageDefault][hostKey])}
                            />
                            <ExcelExportColumn
                                field="start_date"
                                title={localization.toLanguageString(startDateKey, mainMessages[siteLanguageDefault][startDateKey])}
                            />
                            <ExcelExportColumn
                                field="end_date"
                                title={localization.toLanguageString(endDateKey, mainMessages[siteLanguageDefault][endDateKey])}
                            />
                        </ExcelExport>
                    </Col>
                </Row>
                <Row className={"mt-4 mb-2"}>
                    <Col className={"d-flex align-items-center"}>
                        <div className={"k-h5 mb-0"}>
                            {localization.toLanguageString(budgetUtilizationOverviewKey, mainMessages[siteLanguageDefault][budgetUtilizationOverviewKey])}
                        </div>
                    </Col>
                </Row>
                <Row className={"my-2"}>
                    <Col>
                        <Card className={"ea-dashboard-card-border"}>
                            <CardBody>
                                <CardTitle className={"ea-dashboard-card-title"}>
                                    {eA.name?.remix_type?.toUpperCase() === "VARIABLE" ? localization.toLanguageString(totalCostKey, mainMessages[siteLanguageDefault][totalCostKey])
                                        : eA.name?.remix_type ? localization.toLanguageString(totalBudgetVsUtilizedBudgetKey, mainMessages[siteLanguageDefault][totalBudgetVsUtilizedBudgetKey])
                                            : ""}
                                </CardTitle>
                                <div className={"ea-dashboard-chart-margin"}>
                                    {eA.name?.remix_type?.toUpperCase() === "VARIABLE" ? <VariableBudgetUtilizationChart
                                        eA={eA}
                                        pool={pool}
                                        currency={currency}
                                        chartColors={chartColors}
                                        AXIS_LABELS={AXIS_LABELS}
                                        NoDataOverlay={NoDataOverlay}
                                    /> : eA.name?.remix_type ? <PeriodicBudgetUtilizationChart
                                        eA={eA}
                                        pool={pool}
                                        currency={currency}
                                        chartColors={chartColors}
                                        AXIS_LABELS={AXIS_LABELS}
                                        NoDataOverlay={NoDataOverlay}
                                    /> : <NoDataOverlay style={{height: '50vh'}}/>}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className={"mt-4 mb-2"}>
                    <Col className={"d-flex align-items-center"}>
                        <div className={"k-h5 mb-0"}>
                            {localization.toLanguageString(budgetUtilizationDetailsByPeriodKey, mainMessages[siteLanguageDefault][budgetUtilizationDetailsByPeriodKey])}
                        </div>
                    </Col>
                </Row>
                <Row className={"my-2"}>
                    <Col
                        xs={4}
                        className={eA.name?.remix_type?.toUpperCase() === "VARIABLE" ? "d-flex align-items-end" : "d-flex align-items-center"}
                    >
                        {eA.name?.remix_type?.toUpperCase() === "VARIABLE" ? <>
                            <Row>
                                <Col
                                    xs={6}
                                    className={"pe-1"}
                                >
                                    <Text
                                        textkey={startDateKey}
                                        textdefault={mainMessages[siteLanguageDefault][startDateKey]}
                                    />
                                    <DatePicker
                                        value={dateRange.start}
                                        format="d-MMM-y"
                                        field={"start"}
                                        max={dateRange.max}
                                        min={dateRange.min}
                                        onChange={onDateRangeChange}
                                    />
                                </Col>
                                <Col
                                    xs={6}
                                    className={"ps-1"}
                                >
                                    <Text
                                        textkey={endDateKey}
                                        textdefault={mainMessages[siteLanguageDefault][endDateKey]}
                                    />
                                    <DatePicker
                                        value={dateRange.end}
                                        format="d-MMM-y"
                                        field={"end"}
                                        max={dateRange.max}
                                        min={dateRange.min}
                                        onChange={onDateRangeChange}
                                    />
                                </Col>
                            </Row>
                        </> : <>
                            <Label>
                                {localization.toLanguageString(periodKey, mainMessages[siteLanguageDefault][periodKey])}
                            </Label>
                            <DropDownList
                                className={'ksm-dropdownlist mx-2'}
                                value={eA.period}
                                data={periods}
                                disabled={!periods.length}
                                field="period"
                                textField="period_date"
                                onChange={onEAChange}
                            />
                        </>}
                    </Col>
                    <Col
                        xs={8}
                        className={eA.name?.remix_type?.toUpperCase() === "VARIABLE" ? "d-flex align-items-end justify-content-end" : "d-flex align-items-center justify-content-end"}
                    >
                        <Tooltip
                            anchorElement="target"
                            showCallout={true}
                            parentTitle={true}
                            openDelay={0}
                            position="left"
                        >
                            <Button
                                title={localization.toLanguageString(exportAllExcelKey, mainMessages[siteLanguageDefault][exportAllExcelKey])}
                                themeColor={"tertiary"}
                                fillMode={"solid"}
                                svgIcon={fileExcelIcon}
                                disabled={disableExcelExport.period}
                                onClick={exportBudgetPeriodExcel}
                            />
                        </Tooltip>
                        <ExcelExport
                            data={budgetPeriodExcelData}
                            fileName={budgetPeriodExcelFileName}
                            ref={_budgetPeriodExporter}
                            onExportComplete={() => setIsLoading(false)}
                        >
                            <ExcelExportColumn
                                field="prod_num"
                                title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                            />
                            <ExcelExportColumn
                                field="product_description"
                                title={localization.toLanguageString(productDescriptionKey, mainMessages[siteLanguageDefault][productDescriptionKey])}
                            />
                            <ExcelExportColumn
                                field="license_type"
                                title={localization.toLanguageString(licenseTypeKey, mainMessages[siteLanguageDefault][licenseTypeKey])}
                            />
                            <ExcelExportColumn
                                field="price"
                                title={localization.toLanguageString(priceKey, mainMessages[siteLanguageDefault][priceKey])}
                            />
                            <ExcelExportColumn
                                field="quantity"
                                title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                            />
                            <ExcelExportColumn
                                field="total_cost"
                                title={localization.toLanguageString(totalCostKey, mainMessages[siteLanguageDefault][totalCostKey])}
                            />
                            <ExcelExportColumn
                                field="host_id"
                                title={localization.toLanguageString(hostKey, mainMessages[siteLanguageDefault][hostKey])}
                            />
                            <ExcelExportColumn
                                field="start_date"
                                title={localization.toLanguageString(startDateKey, mainMessages[siteLanguageDefault][startDateKey])}
                            />
                            <ExcelExportColumn
                                field="end_date"
                                title={localization.toLanguageString(endDateKey, mainMessages[siteLanguageDefault][endDateKey])}
                            />
                        </ExcelExport>
                    </Col>
                </Row>
                <Row className={"my-2"}>
                    <Col>
                        <Card className={"ea-dashboard-card-border"}>
                            <CardBody>
                                <CardTitle>
                                    <Row>
                                        <Col className={"ea-dashboard-card-title"}>
                                            {localization.toLanguageString(budgetUtilizationDetailsByHostKey, mainMessages[siteLanguageDefault][budgetUtilizationDetailsByHostKey])}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <HostUtilizationChart
                                                eA={eA}
                                                subscriptionPool={subscriptionPool}
                                                currency={currency}
                                                chartColors={chartColors}
                                                dateRange={dateRange}
                                                ALL_USERS_KEY={ALL_USERS_KEY}
                                                AXIS_LABELS={AXIS_LABELS}
                                                NoDataOverlay={NoDataOverlay}
                                            />
                                        </Col>
                                    </Row>
                                </CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card className={"ea-dashboard-card-border"}>
                            <CardBody>
                                <CardTitle>
                                    <Row>
                                        <Col className={"ea-dashboard-card-title"}>
                                            {localization.toLanguageString(top10ProductsSpentKey, mainMessages[siteLanguageDefault][top10ProductsSpentKey])}
                                        </Col>
                                    </Row>
                                </CardTitle>
                                <Row>
                                    <Col>
                                        <ProductUtilizationChart
                                            eA={eA}
                                            subscriptionPool={subscriptionPool}
                                            currency={currency}
                                            chartColors={chartColors}
                                            dateRange={dateRange}
                                            ALL_USERS_KEY={ALL_USERS_KEY}
                                            AXIS_LABELS={AXIS_LABELS}
                                            NoDataOverlay={NoDataOverlay}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default EADashboard;