import React, {useContext, useEffect, useRef, useState} from 'react';
import uuid from 'react-uuid';
import moment from 'moment';
import axios from 'axios';
import {cloneDeep} from 'lodash';
import config from '../../../config.js';
import UserContext from '../../common/UserContext.js';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import {ColumnMenu, NoWrapCell, TextAlignMiddleCell} from '../../common/Grid.js';
import Alert from '../../common/Alert.js';
import {toHtml} from '../../common/utilities.js';

// kendo react
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import {Button} from '@progress/kendo-react-buttons';
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {Label} from '@progress/kendo-react-labels';
import {Input, TextArea} from '@progress/kendo-react-inputs';
import {AutoComplete} from '@progress/kendo-react-dropdowns';
import {Upload} from '@progress/kendo-react-upload';
import {Popover} from '@progress/kendo-react-tooltip';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {AnchorWrap, Text} from '../../common/MultilingualText.js';
import {
    assignProductDuplicateHostIDMessageErrorKey,
    duplicateHostKey,
    assignProductDuplicateHostUniqueMessageErrorKey,
    invalidFileKey,
    assignProductsGenericMessageIssueErrorKey,
    assignProductsGenericMessagePleaseErrorKey,
    genericErrorTitleKey,
    hostIdFormattingKey,
    invalidHostKey,
    cancelChangesKey,
    saveHostIDKey,
    assignHostKey,
    invalidHostColonKey,
    serialNumberFormattingKey,
    invalidSerialNumberKey,
    specificHostFileKey,
    aliasUniqueKey,
    duplicateAliasKey,
    mainMessages,
    alreadyAssignedKey,
    onePerHostKey,
    hostPendingSpecialKey,
    imeiAssignedQuantityKey,
    incorrectNumberImeiKey,
    confirmKey,
    keysightGenerateLicensesV2Key,
    specialHandlingKey,
    quantityConsumedSessionKey,
    quantityConsumedKey,
    descriptionKey,
    licenseTypeKey,
    productNumberKey,
    qtyKey,
    contactUsKey,
    aliasKey,
    assetInfoKey,
    cityKey,
    countryKey,
    hostMetaDataKey,
    notesKey,
    stateKey, uploadFileKey,
} from '../../../assets/text/MultilingualText.js';
import KSMPopover from "../../common/Popovers";
import {InfoIcon} from "../../common/icons";

function AssignProductsModal(props) {
    const {
        isLoading,
        setIsLoading,
        setHeaderInfo,
        assignedProducts,
        setAssignedProducts,
        selectedProducts,
        setSelectedProducts,
        headerInfo,
        hostIDType,
        hostModal,
        setHostModal,
        hostIsSerial,
        setHostIsSerial,
        hostIDLabel,
        setHostIDLabel,
        hostIDHint,
        setHostIDHint,
        hostIDError,
        hostIDPatterns,
        setHostIDPatterns,
        hostIDSuggestions,
        serialNumberLabel,
        setSerialNumberLabel,
        serialNumberHint,
        setSerialNumberHint,
        serialNumberError,
        serialNumberPatterns,
        setSerialNumberPatterns,
        isVisible,
        setIsVisible,
        futureSubPoolValues,
        setFutureSubPoolValues,
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [hostConfID, setHostConfID] = useState(null);

    const [hostID, setHostID] = useState("");
    const hostIDPopover = useRef(null);
    const hostIDAnchor = useRef(null);

    const [serialNumber, setSerialNumber] = useState("");
    const serialNumberPopover = useRef(null);

    const [alias, setAlias] = useState("");
    const [assetInformation, setAssetInformation] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [notes, setNotes] = useState("");

    const [multiHost, setMultiHost] = useState("");

    const [hostFile, setHostFile] = useState("");
    const [hostFileExtension, setHostFileExtension] = useState("");

    const [errors, setErrors] = useState([]);
    const [isValid, setIsValid] = useState({
        hostID: true,
        serialNumber: true,
        multiHost: true,
    });

    const titleStyle = {
        fontWeight: "normal",
        margin: 0,
        color: "var(--color-text-primary)"
    };

    /*
     * save() saves product(s) to host(s)
    */
    const save = () => {
        let products;
        let hostData = [];
        let headers = {
            'Authorization': 'Bearer ' + accessToken
        };
        let periodSelection = new URLSearchParams(window.location.search).get("show");

        // send correct host id or multiple hosts data
        hostModal === "HOST" ? hostData = [hostID]
            : hostModal === "MULTI_HOST" && multiHost.length ? hostData = multiHost.split('\n').filter(host => host)
                : hostData = []

        hostData.forEach((host, idx) => {
            return hostData[idx] = host?.trim() || '';
        })

        products = selectedProducts
            .filter(product => product.selected)
            .map(product => {
                let license = product.license_type.filter(lt => lt.selected);
                let hostid_type_pk = license[0].hostid_type_pk;
                let ea_alloc_id = product.ea_alloc_id;
                let product_id = product.product_id;
                let prod_num = product.prod_num_display;
                let status = "ASSIGNED"
                let quantity = product.assign_quantity;
                let ppks_flag = product.ppks_flag
                let license_type = license[0].license_type;
                let period_start_date = moment(product.period_start_date).format('YYYY-MM-DD HH:mm:ss');
                let period_end_date = moment(product.period_end_date).format('YYYY-MM-DD HH:mm:ss');
                let ea_compatibility = license[0].ea_compatibility;

                return {
                    "host_conf_id": hostConfID,
                    "hostid_type_pk": hostid_type_pk,
                    "ea_alloc_id": ea_alloc_id,
                    "product_id": product_id,
                    "prod_num": prod_num,
                    "status": status,
                    "quantity": quantity,
                    "ppks_flag": ppks_flag,
                    "license_type": license_type,
                    "period_start_date": period_start_date,
                    "period_end_date": period_end_date,
                    "ea_compatibility": ea_compatibility
                }
            })

        let inputJSON = {
            "ea_id": headerInfo.ea_id,
            "variableoversubflag": headerInfo.variableoversubflag,
            "product_sel_period": periodSelection,
            "host_id_type_val": hostIDType?.trim() || '',
            "host_val": hostData,
            "serial_number": serialNumber?.trim() || '',
            "alias": alias?.trim() || '',
            "asset_info": assetInformation?.trim() || '',
            "city": city?.trim() || '',
            "state": state?.trim() || '',
            "country": country?.trim() || '',
            "notes": notes?.trim() || '',
            "ea_products": products
        }

        let data = {
            module: "EA",
            sub_module: "hosts",
            action: "SAVE",
            input_json: [inputJSON]
        }

        let formData = new FormData();
        formData.append('File', hostFile);
        formData.append('Data', JSON.stringify(data));

        setIsLoading(true);

        axios.post(
            config.ea_request_license.HOST,
            formData,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    data = response.data;

                    // save host
                    let saveHostData = data.save_host_response || [];
                    saveHost(saveHostData);

                    // update sub pool
                    let eaHeaderInfo = data.sub_pool_data[0];
                    headerInfo.total_pool = eaHeaderInfo.total_pool;
                    headerInfo.selected_sub_pool = eaHeaderInfo.selected_sub_pool;
                    headerInfo.consumed_pool = eaHeaderInfo.redeemed_pool;
                    headerInfo.remaining_pool = eaHeaderInfo.remaining_pool
                    setHeaderInfo(JSON.parse(JSON.stringify(headerInfo)));

                    if (headerInfo.variableoversubflag === 'TRUE') {
                        for (const pool of data['list_sub_pool_values']) {
                            const year = pool.subpoolyear
                            futureSubPoolValues[year].availablesubscriptionpool = pool.availablesubscriptionpool
                            futureSubPoolValues[year].redeemedsubscriptionpool = pool.redeemedsubscriptionpool
                            futureSubPoolValues[year].selectedsubscriptionpool = pool.selectedsubscriptionpool
                        }
                        setFutureSubPoolValues(JSON.parse(JSON.stringify(futureSubPoolValues)))
                    }

                    setErrors([]);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to POST Host Assignment", error);
                let field;
                let message;
                let status = typeof error.response.status === 'undefined' ? "" : error.response.status;
                let errorCode = typeof error.response.data.error_code === 'undefined' ? "" : error.response.data.error_code;

                if (status === 400) {
                    switch (errorCode) {
                        case "MOD_211":
                        case "MOD_214":
                            field = "hostID";
                            break;
                        case "MOD_212":
                            field = "serialNumber";
                            break;
                        case "MOD_213":
                            field = "multi";
                            break;
                        case "MOD_209":
                        case "MOD_210":
                        case "MOD_LICENSE_226":
                        case "MOD_100":
                            field = "file";
                            break;
                        case "MOD_EA_160":
                            field = "alias";
                            break;
                        case "MOD_EA_161":
                            field = "quantityConsumed";
                            break;
                        case 'MOD_EA_158':
                        case 'MOD_EA_132':
                            if (error.response.data.hasOwnProperty('sub_pool_data')) {
                                let eaHeaderInfo = error.response.data['sub_pool_data'][0]
                                headerInfo.total_pool = eaHeaderInfo.total_pool
                                headerInfo.selected_sub_pool = eaHeaderInfo.selected_sub_pool
                                headerInfo.consumed_pool = eaHeaderInfo.redeemed_pool
                                headerInfo.remaining_pool = eaHeaderInfo.remaining_pool - eaHeaderInfo.selected_sub_pool
                                setHeaderInfo(JSON.parse(JSON.stringify(headerInfo)))
                            }

                            if (error.response.data.hasOwnProperty('list_sub_pool_values')) {
                                if (headerInfo.variableoversubflag === 'TRUE') {
                                    for (const pool of error.response.data['list_sub_pool_values']) {
                                        const year = pool.subpoolyear
                                        futureSubPoolValues[year].availablesubscriptionpool = pool.availablesubscriptionpool
                                        futureSubPoolValues[year].redeemedsubscriptionpool = pool.redeemedsubscriptionpool
                                        futureSubPoolValues[year].selectedsubscriptionpool = pool.selectedsubscriptionpool
                                    }
                                    setFutureSubPoolValues(JSON.parse(JSON.stringify(futureSubPoolValues)))
                                }
                            }
                            field = "generic";
                            break;
                        default:
                            field = "generic";
                            break;
                    }
                    message = field === "alias" ? localization.toLanguageString(aliasUniqueKey, mainMessages[siteLanguageDefault][aliasUniqueKey])
                        : field === "quantityConsumed" ? localization.toLanguageString(quantityConsumedSessionKey, mainMessages[siteLanguageDefault][quantityConsumedSessionKey])
                            : error.response.data.display_message;
                }

                let hostError = {
                    field: field,
                    message: message
                };
                showError(hostError);
                setIsLoading(false);
            });
    }

    /*
     * resetAssignHost() resets all assign host modal fields
    */
    const resetAssignHost = () => {
        setHostModal("");
        setHostIsSerial("");
        setHostID("");
        setHostIDLabel("");
        setHostIDHint("");
        setHostIDPatterns([]);
        setSerialNumber("");
        setSerialNumberLabel("");
        setSerialNumberHint("");
        setSerialNumberPatterns([]);
        setMultiHost("");
        setHostFile({});
        setAlias("");
        setAssetInformation("");
        setCity("");
        setState("");
        setCountry("");
        setNotes("");
        removeErrors();
    }

    /*
     * closeAssignHost() closes the assign host modal and reset assign host modal fields
    */
    const closeAssignHost = () => {
        resetAssignHost();
        setIsVisible(isVisible => ({...isVisible, modal: false}));
    };

    /*
     * onHostFileUpload() uploads the host file
    */
    const onHostFileUpload = (event) => {
        setHostFileExtension(event.target.files[0].extension);
        setHostFile(event.target.files[0].getRawFile());
    };

    /*
     * showError() appends to errors object and redlines field
     * @param {error} the error object containing the attributes for the Alert dialog and the field to redline
     *  {
     *      field: hostID || serialNumber || multi || file || database || onePerHost || duplicateHost || qtyDistribution
     *      title: error's title (note: only applicable for error.field onePerHost || duplicateHost || qtyDistribution)
     *      message: error's message
     *  }
    */
    const showError = (error) => {
        let genericMessagePlease = localization.toLanguageString(
            assignProductsGenericMessagePleaseErrorKey,
            mainMessages[siteLanguageDefault][assignProductsGenericMessagePleaseErrorKey]
        );
        let genericMessageIssue = localization.toLanguageString(
            assignProductsGenericMessageIssueErrorKey,
            mainMessages[siteLanguageDefault][assignProductsGenericMessageIssueErrorKey]
        );

        let generic = {
            field: "generic",
            message:
                <div>{genericMessagePlease} {contactUs} {genericMessageIssue}</div>
        };

        error = error.field === "database" ? generic : error;

        // if all values in the error object are not null, render the error
        if (!Object.values(error).every(e => !e)) {
            setErrors(errors => [...errors, error]);
            setIsValid(isValid => ({...isValid, [error.field]: false}));
        } else {
            setErrors(errors => [...errors, generic]);
        }
    }

    /*
     * removeErrors() removes all errors and redlines for all fields
    */
    const removeErrors = () => {
        setErrors([]);
        setIsValid({
            hostID: true,
            serialNumber: true,
            multiHost: true,
        });
    };

    // finds duplicates in array
    const findDuplicates = arr => arr.filter((e, i) => arr.indexOf(e) !== i);

    // equals compares two js arrays to see if they are equal
    const equals = (a, b) => a.length === b.length && a.every((v, i) => v === b[i]);

    /*
     * validateOnePerHost() validates if the one per host product is not already assigned to host
     * @param {selected} a deep copy of selectedProducts
     * @return true if product is not already assigned to host
    */
    const validateOnePerHost = (selected) => {
        // case: validate one per host product is not already assigned to host
        let unassignable = [];
        let hostIDTrim = hostID?.trim() || '';
        let host = assignedProducts.find(host =>
            host.host_id &&
            [hostIDTrim] &&
            equals(host.host_id, [hostIDTrim])
        )
        if (host) {
            selected
                .filter(product => product.selected)
                .forEach(selectedProduct => {
                    let product = host.products.find(product => product.parent_alloc_id === selectedProduct.ea_alloc_id);
                    let license = selectedProduct.license_type.filter(lt => lt.selected);
                    if (license[0].redeem_one_per_host_flag.toUpperCase() === "Y" && product) {
                        unassignable.push(product.prod_num_display);
                    }
                });
        }

        if (unassignable.length) {
            // show error for attempting to assign a product that is one per host
            let onePerHostTitleProduct = localization.toLanguageString(
                onePerHostKey,
                mainMessages[siteLanguageDefault][onePerHostKey]
            );
            let message = localization.toLanguageString(
                alreadyAssignedKey,
                mainMessages[siteLanguageDefault][alreadyAssignedKey]
            );

            let onePerHostError = {
                field: "onePerHost",
                title: onePerHostTitleProduct,
                message: <>
                    {message}
                    <br/>
                    {unassignable.join(', ')}
                </>
            };
            showError(onePerHostError);
        }

        return !unassignable.length;
    }

    /*
     * validateHost() validates the user host id || serial number input on the frontend prior to validating the
     * host id || serial number input on the backend
     * @param {selected} a deep copy of selectedProducts
    */
    const validateHost = (selected) => {
        let isValidHostID = !hostID ? false : hostIDPatterns.some(regex => regex.test(hostID?.trim() || ''));
        let isValidSerialNumber = (!serialNumber && hostIsSerial === "Y") ? false : serialNumberPatterns.some(regex => regex.test(serialNumber?.trim() || ''));

        // show errors for invalid host ids and serial numbers
        if (!isValidHostID) {
            let hostError = {
                field: "hostID",
                message: hostIDError
            };
            showError(hostError);
        }
        if (!isValidSerialNumber && (hostIsSerial === "Y" || hostIsSerial === "O")) {
            let serialError = {
                field: "serialNumber",
                message: serialNumberError
            };
            showError(serialError);
        }

        // call regex validation api for the following cases
        if (isValidHostID && isValidSerialNumber && (hostIsSerial === "Y" || hostIsSerial === "O")) {
            if (validateOnePerHost(selected)) {
                save();
            }
        }
        if (isValidHostID && hostIsSerial === "N") {
            if (validateOnePerHost(selected)) {
                save();
            }
        }
    };

    /*
     * validateDuplicates() validates if the hosts inputted are not duplicates
     * @return true if no duplicates are inputted for multi host
    */
    const validateDuplicates = () => {
        // case: validate multi hosts are unique
        let duplicateHosts = [];
        let multiHostData = multiHost.length ? multiHost.split('\n').filter(host => host) : [];
        multiHostData.forEach((host, idx) => {
            return multiHostData[idx] = host?.trim() || ''
        })
        duplicateHosts = findDuplicates(multiHostData);
        let duplicates = [...new Set(duplicateHosts)];

        if (duplicateHosts.length) {
            // show error for attempting to assign duplicate hosts
            let duplicateHostTitle = localization.toLanguageString(
                duplicateHostKey,
                mainMessages[siteLanguageDefault][duplicateHostKey]
            );
            let duplicateHostIDMessage = localization.toLanguageString(
                assignProductDuplicateHostIDMessageErrorKey,
                mainMessages[siteLanguageDefault][assignProductDuplicateHostIDMessageErrorKey]
            );
            let duplicateHostUniqueMessage = localization.toLanguageString(
                assignProductDuplicateHostUniqueMessageErrorKey,
                mainMessages[siteLanguageDefault][assignProductDuplicateHostUniqueMessageErrorKey]
            );
            let message = duplicates.map(host =>
                <div key={'duplicate-host-error-' + host}>
                    {duplicateHostIDMessage} {host} {duplicateHostUniqueMessage}
                    <br/>
                </div>
            );

            let duplicateHostError = {
                field: "duplicateHost",
                title: duplicateHostTitle,
                message: message
            };
            showError(duplicateHostError);
        }

        return !duplicateHosts.length;
    }

    /*
     * validateDistribution() validates if the assign quantity can be equally distributed across the number of inpuuted hosts
     * @param {selected} a deep copy of selectedProducts
     * @return true if the assign quantity can be equally distributed across the number of inputted hosts
    */
    const validateDistribution = (selected) => {
        // case: validate multi hosts quantities are distributable
        let isDistributable = false;
        let qtyErrors = [];
        let multiHostData = multiHost.length ? multiHost.split('\n').filter(host => host) : [];
        multiHostData.forEach((host, idx) => {
            return multiHostData[idx] = host?.trim() || ''
        })
        let hostCount = multiHostData.length;

        selected
            .filter(p => p.selected)
            .forEach((selectedProduct) => {
                let qty = parseInt(selectedProduct.unassigned_quantity);
                let assignQty = parseInt(selectedProduct.assign_quantity);
                let distribution = assignQty * hostCount;

                // check if there is enough qty to be distributed
                // and the product's qty will be evenly distributed across each host
                qty - distribution >= 0 && distribution % hostCount === 0 ? isDistributable = true
                    : qtyErrors.push({
                        "product": selectedProduct.prod_num_display
                    })
            });

        if (!isDistributable && qtyErrors.length) {
            // show error for attempting to assign invalid quantities among hosts
            let qtyDistributionTitle = localization.toLanguageString(
                incorrectNumberImeiKey,
                mainMessages[siteLanguageDefault][incorrectNumberImeiKey]
            );
            let qtyDistributionMessage = localization.toLanguageString(
                imeiAssignedQuantityKey,
                mainMessages[siteLanguageDefault][imeiAssignedQuantityKey]
            );

            let qtyDistributionError = {
                field: "qtyDistribution",
                title: qtyDistributionTitle,
                message: qtyDistributionMessage
            }
            showError(qtyDistributionError);
        }

        return (isDistributable && !qtyErrors.length);
    }

    /*
     * validateMultiHost() validates the user multiple hosts or imei's input on the frontend prior to validating the
     * @param {selected} a deep copy of selectedProducts
     * host id || serial number input on the backend
    */
    const validateMultiHost = (selected) => {
        let invalidMultiHosts = [];
        let multiHostData = multiHost.length ? multiHost.split('\n').filter(host => host) : [];

        multiHostData.forEach((host) => {
            // isValidHost is true if the host matches all regex pattern in hostIDPatterns
            let isValidHost = hostIDPatterns.some(regex => regex.test(host?.trim() || ''));
            if (!isValidHost) invalidMultiHosts.push(host?.trim() || '');
        });

        // validate if the multi host input is not empty and there are no invalidMultiHosts
        if (multiHostData.length && !invalidMultiHosts.length) {
            // validate duplicate multi host input
            if (validateDuplicates()) {
                // validate assign quantity distribution across multi host input
                if (validateDistribution(selected)) {
                    save();
                }
            }
        } else {
            let message;
            let invalidMultiHostSubmessage = invalidMultiHosts.length ? localization.toLanguageString(
                    invalidHostColonKey,
                    mainMessages[siteLanguageDefault][invalidHostColonKey])
                : "";
            message = <>
                {hostIDError} <br/>
                {invalidMultiHostSubmessage} {invalidMultiHosts.length ? invalidMultiHosts.join(', ') : ""}
            </>

            let multiHostError = {
                field: "multi",
                message: message
            };
            showError(multiHostError);
        }
    }

    /*
     * validateFile() validates modal type and file extension are the same
    */
    const validateFile = () => {
        if ((hostModal === "C2V" && hostFileExtension === ".c2v") ||
            (hostModal === "HOST_FILE_BIN" && hostFileExtension === ".bin")) {
            save();
        } else {
            let fileError = {
                field: "file",
                message: hostIDError
            };
            showError(fileError);
        }
    }

    /*
     * validate() validates the assigned host
    */
    const validate = () => {
        // reset errors
        removeErrors();

        let selected = cloneDeep(selectedProducts);

        if (hostModal === "HOST") {
            validateHost(selected);
        } else if (hostModal === "MULTI_HOST") {
            validateMultiHost(selected);
        } else if (hostModal === "C2V") {
            validateFile();
        } else if (hostModal === "HOST_FILE_BIN") {
            validateFile();
        } else if (hostModal === "PARTIAL") {
            save();
        }
    }

    /*
     * saveHost() saves the assigned host
     * @param {updates} is the products containing new ea alloc ids that must be updated
    * */
    const saveHost = (updates = []) => {
        let host;
        let hostId;
        let panelBarTitle;

        let selected = cloneDeep(selectedProducts);

        // get host if host already exists in assignedProducts and sets host id and panel bar title for accordions
        if (hostModal === "HOST") {
            const hostIDTrim = hostID?.trim() || '';
            const serialNumberTrim = serialNumber?.trim() || '';

            setHostID(hostIDTrim);
            setSerialNumber(serialNumberTrim);
            hostId = [hostIDTrim];

            panelBarTitle = hostIDTrim;

            // add alias to panel par title
            alias ? panelBarTitle += (' "' + alias + '"') : panelBarTitle += "";

            host = assignedProducts.find(host =>
                host.host_id &&
                [hostIDTrim] &&
                equals(host.host_id, [hostIDTrim])
            )
            createHost(panelBarTitle, host, hostId, selected, updates);
        } else if (hostModal === "MULTI_HOST") {
            // format multiple hosts or imei's to list of string
            let multiHostData = multiHost.length ? multiHost.split('\n').filter(host => host) : [];

            multiHostData.forEach((multiHost, index) => {
                hostId = [multiHost];
                panelBarTitle = multiHost;
                host = assignedProducts.find(host =>
                    host.host_id &&
                    [multiHost] &&
                    equals(host.host_id, [multiHost])
                );

                createHost(panelBarTitle, host, hostId, selected, updates);
            })
        } else if (hostModal === "C2V" || hostModal === "HOST_FILE_BIN") {
            let fileHostName;
            let fileHostID;

            let isFileName = updates
                .map(u => u.file_name)
                .every((val, i, arr) => val === arr[0]);
            let isFileHost = updates
                .map(u => u.host_id)
                .every((val, i, arr) => val === arr[0]);

            if (isFileName) {
                fileHostName = updates[0].file_name;
            }
            if (isFileHost) {
                fileHostID = updates[0].host_id;
            }

            if (fileHostID) {
                // handle c2v or bin files
                hostId = [fileHostID];
                panelBarTitle = fileHostID;
                host = assignedProducts.find(host =>
                    host.host_id &&
                    [fileHostID] &&
                    equals(host.host_id, [fileHostID])
                );
            } else {
                // handle fingerprint files
                hostId = [fileHostName];
                panelBarTitle = fileHostName;
                host = assignedProducts.find(host =>
                    host.host_id &&
                    [fileHostName] &&
                    equals(host.host_id, [fileHostName]) &&
                    host.file.name === hostFile.name &&
                    host.file.size === hostFile.size &&
                    host.file.lastModified === hostFile.lastModified
                );
            }
            createHost(panelBarTitle, host, hostId, selected, updates);
        } else if (hostModal === "PARTIAL") {
            panelBarTitle = localization.toLanguageString(hostPendingSpecialKey, mainMessages[siteLanguageDefault][hostPendingSpecialKey]);
            host = assignedProducts.find(host =>
                host.transaction_id &&
                host.is_partial === "Y"
            );
            createHost(panelBarTitle, host, [null], selected, updates);
        } else {
            hostId = [];
            panelBarTitle = "";
            host = null;
        }
    }

    /*
     * createHost() creates the accordion for the products assigned to a host
     * @param {panelBarTitle} is the assignedProduct's accordion title
     * @param {host} is the existing assignedProduct object in assignedProducts
     * @param {hostId} is the assignedProduct's host_id
     * @param {selected} is a deep copy of selectedProducts
     * @param {updates} is the products containing new ea alloc ids that must be updated
    */
    const createHost = (panelBarTitle, host, hostId, selected, updates) => {
        let transactionID;

        // get unique transaction id for existing hosts
        // or add a unique transaction id for new hosts
        transactionID = host ? host.transaction_id : uuid();

        let hosts = [...assignedProducts];

        // 1a. update assignedProducts for the assigned products accordions for existing host
        if (host) {
            let isPartial = "N";

            // find index of the host in assignedProducts array
            let hostIndex = hosts.findIndex(hostUpdate => hostUpdate === host);

            // set host file if host file is empty string
            if (hosts[hostIndex].file === "") {
                hosts[hostIndex].file = hostFile
            }

            // add the product to the host
            selected
                .filter(product => product.selected)
                .forEach(selectedProduct => {
                    let newProduct = cloneDeep(selectedProduct);
                    newProduct["unassigned_quantity"] = parseInt(selectedProduct.assign_quantity);
                    newProduct["parent_alloc_id"] = selectedProduct.ea_alloc_id;
                    newProduct["saved_period_end_date"] = newProduct.period_end_date;
                    newProduct["saved_period_start_date"] = newProduct.period_start_date;
                    newProduct["status"] = "ASSIGNED";
                    newProduct["file_name"] = null;

                    //Need these two fields for delete modal
                    newProduct.panel_bar_title = hosts[hostIndex].panel_bar_title
                    newProduct.transaction_id = hosts[hostIndex].transaction_id

                    // update the assigned products with new ea alloc id's
                    let index = updates.findIndex(u => u.parent_alloc_id === selectedProduct.ea_alloc_id);
                    if (updates[index]) {
                        newProduct["ea_alloc_id"] = updates[index].ea_alloc_id;
                    }

                    // set is partial flag
                    let license = newProduct.license_type.filter(lt => lt.selected);
                    if (license[0].ea_compatibility === "P") {
                        isPartial = "Y";
                    }

                    delete newProduct['selected'];
                    delete newProduct['disabled'];
                    delete newProduct['license_type_display'];

                    hosts[hostIndex].products.push(newProduct);
                })

            hosts[hostIndex].is_partial = isPartial;
        }
        // 1b. update assignedProducts for the assigned products accordions for a new host
        else {
            let isPartial = "N";
            let hostProducts = cloneDeep(selectedProducts)
                .filter(p => p.selected)
                .map((hostProduct) => {
                    hostProduct['unassigned_quantity'] = parseInt(hostProduct.assign_quantity);
                    hostProduct["parent_alloc_id"] = hostProduct.ea_alloc_id;
                    hostProduct["saved_period_end_date"] = hostProduct.period_end_date;
                    hostProduct["saved_period_start_date"] = hostProduct.period_start_date;
                    hostProduct["status"] = "ASSIGNED";
                    hostProduct["file_name"] = null;

                    //Need these two fields for delete modal
                    hostProduct.panel_bar_title = panelBarTitle
                    hostProduct.transaction_id = transactionID

                    // update the assigned products with new ea alloc id's
                    let index = updates.findIndex(u => u.parent_alloc_id === hostProduct.ea_alloc_id);
                    if (updates[index]) {
                        hostProduct["ea_alloc_id"] = updates[index].ea_alloc_id;
                    }

                    // set is partial flag
                    let license = hostProduct.license_type.filter(lt => lt.selected);
                    if (license[0].ea_compatibility === "P") {
                        isPartial = "Y";
                    }

                    delete hostProduct['selected'];
                    delete hostProduct['disabled'];
                    delete hostProduct['license_type_display'];

                    return hostProduct;
                });

            let assignedProduct = {
                transaction_id: transactionID,
                is_partial: isPartial,
                host_id: hostId,
                serial_id: serialNumber,
                alias: alias,
                asset_info: assetInformation,
                city: city,
                state: state,
                country: country,
                notes: notes,
                products: hostProducts,
                file: hostFile,
                panel_bar_title: panelBarTitle,
                expanded: ['.0'],
                icons: {
                    checked: true,
                },
            };

            hosts = [...assignedProducts, assignedProduct];
        }

        let newSelectedProducts = selectedProducts.map(item => {
            let checkedProducts = selected.filter(product => product.selected)
            for (const checked of checkedProducts) {
                if (item.ea_alloc_id === checked.ea_alloc_id) {
                    item.unassigned_quantity -= checked.assign_quantity;
                    /*item.assign_quantity = item.redeem_one_per_host_flag === "Y" ? 1
                        : item.unassigned_quantity === 1 ? 1
                            : null;*/
                }
            }
            item.selected = false
            return item
        })

        setAssignedProducts(hosts)
        setSelectedProducts(newSelectedProducts)

        // close modal
        closeAssignHost();
    }

    const AnchorText = AnchorWrap(Text);
    const contactUs = <AnchorText
        className={"header-contact-us"}
        href={config.keysight + "us/en/contact.html"}
        data-trigger="false"
        textkey={contactUsKey}
        textdefault={mainMessages[siteLanguageDefault][contactUsKey]}
    />

    const hostFileButtonAnchor = useRef(null);
    const hostFilePopoverAnchor = useRef(null);

// filter the Host ID suggestion dropdown based on user's input
    const [filteredHostIDs, setFilteredHostIDs] = useState(hostIDSuggestions);
    const filterHostIDs = (value) => {
        let filtered = hostIDSuggestions.filter(s => s.suggestion.toLowerCase().includes(value.toLowerCase()));

        // auto populate on selection of a suggested host id
        if (hostModal === "HOST" && filtered.length === 1 && value === filtered[0].suggestion) {
            let autoPopulate = filtered[0];
            autoPopulate.host_conf_id ? setHostConfID(autoPopulate.host_conf_id) : setHostConfID(null);
            autoPopulate.node_id ? setHostID(autoPopulate.node_id) : setHostID("");
            autoPopulate.serial_number ? setSerialNumber(autoPopulate.serial_number) : setSerialNumber("");
            autoPopulate.alias ? setAlias(autoPopulate.alias) : setAlias("");
            autoPopulate.asset_info ? setAssetInformation(autoPopulate.asset_info) : setAssetInformation("");
            autoPopulate.city ? setCity(autoPopulate.city) : setCity("");
            autoPopulate.state ? setState(autoPopulate.state) : setState("");
            autoPopulate.country ? setCountry(autoPopulate.country) : setCountry("");
            autoPopulate.notes ? setNotes(autoPopulate.notes) : setNotes("");
        } else {
            setHostConfID(null);
            setHostID(value);
            setSerialNumber("");
            setAlias("");
            setAssetInformation("");
            setCity("");
            setState("");
            setCountry("");
            setNotes("");
        }
        value ? setFilteredHostIDs(filtered) : setFilteredHostIDs(hostIDSuggestions);
    };

// handle on change for host id input & auto populate with user_profile_tagged_host_ids
    const onHostIDChange = (e) => {
        setIsVisible(isVisible => ({...isVisible, hostIDHint: false}));
        setIsValid(isValid => ({...isValid, hostID: true}));
        filterHostIDs(e.value);
    }

//set focus to host id input field when host modal is HOST
    useEffect(() => {
        if (hostModal === "HOST") {
            hostIDAnchor.current._input.focus();
        }
    }, []);

// dynamically create assign host modal
    const AssignHostModal = () => {
        let inputs = <></>;
        switch (hostModal) {
            case "HOST":
                inputs = <>
                    <Row>
                        <Col>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.2rem',
                                lineHeight: '2rem'
                            }}>
                                <Label>{toHtml(hostIDLabel)}</Label>
                                <InfoIcon
                                    onClick={() => {
                                        setIsVisible(isVisible => ({
                                            ...isVisible,
                                            hostIDHint: !isVisible.hostIDHint,
                                            serialNumberHint: false
                                        }));
                                    }}
                                    size={"medium"}
                                    style={{
                                        color: 'var(--keysight-secondary)',
                                        cursor: 'pointer',
                                    }}
                                />
                            </div>
                            <KSMPopover
                                show={isVisible.hostIDHint}
                                setShowHandler={() => {
                                    setIsVisible(isVisible => ({
                                        ...isVisible,
                                        hostIDHint: false,
                                    }));
                                }}
                                anchor={hostIDPopover.current}
                                position={'right'}
                                type={'warning'}
                                style={{maxWidth: 600}}
                                message={
                                    <>
                                        <b>
                                            {localization.toLanguageString(hostIdFormattingKey, mainMessages[siteLanguageDefault][hostIdFormattingKey])}
                                        </b>
                                        <br/>
                                        {toHtml(hostIDHint)}
                                    </>
                                }
                            />
                            <div ref={hostIDPopover}>
                                <AutoComplete
                                    ref={hostIDAnchor}
                                    value={hostID}
                                    data={filteredHostIDs}
                                    textField="suggestion"
                                    onChange={onHostIDChange}
                                    valid={isValid.hostID}
                                    listNoDataRender={(e) => null}
                                />
                            </div>
                        </Col>
                        <Col>
                            {hostIsSerial === "Y" || hostIsSerial === "O" ?
                                <>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.2rem',
                                        lineHeight: '2rem'
                                    }}>
                                        <Label>
                                            {hostIsSerial === "O" ? toHtml(serialNumberLabel + " (Optional)") : toHtml(serialNumberLabel)}
                                        </Label>
                                        <InfoIcon
                                            onClick={() => {
                                                setIsVisible(isVisible => ({
                                                    ...isVisible,
                                                    hostIDHint: false,
                                                    serialNumberHint: !isVisible.serialNumberHint
                                                }));
                                            }}
                                            size={"medium"}
                                            style={{
                                                color: 'var(--keysight-secondary)',
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </div>
                                    <KSMPopover
                                        show={isVisible.serialNumberHint}
                                        setShowHandler={() => {
                                            setIsVisible(isVisible => ({
                                                ...isVisible,
                                                serialNumberHint: false,
                                            }));
                                        }}
                                        anchor={serialNumberPopover.current}
                                        position={'left'}
                                        type={'warning'}
                                        style={{maxWidth: 600}}
                                        message={
                                            <>
                                                <b>
                                                    {localization.toLanguageString(serialNumberFormattingKey, mainMessages[siteLanguageDefault][serialNumberFormattingKey])}
                                                </b>
                                                <br/>
                                                {toHtml(serialNumberHint)}
                                            </>
                                        }
                                    />
                                    <div ref={serialNumberPopover}>
                                        <AutoComplete
                                            value={serialNumber}
                                            onChange={(e) => {
                                                setIsValid(isValid => ({
                                                    ...isValid,
                                                    serialNumber: true
                                                }));
                                                setSerialNumber(e.value);
                                            }}
                                            valid={isValid.serialNumber}
                                            listNoDataRender={(e) => null}
                                        />
                                    </div>
                                </>
                                :
                                <></>
                            }
                        </Col>
                    </Row>
                    <Row className={"mt-4"}>
                        <div
                            className={"k-h5"}
                        >
                            <Text
                                textkey={hostMetaDataKey}
                                textdefault={mainMessages[siteLanguageDefault][hostMetaDataKey]}
                            />
                        </div>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <Label>
                                        {localization.toLanguageString(aliasKey, mainMessages[siteLanguageDefault][aliasKey])}
                                    </Label>
                                    <Input
                                        value={alias}
                                        maxLength={50}
                                        onChange={(e) => {
                                            setAlias(e.value)
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Label>
                                        {localization.toLanguageString(assetInfoKey, mainMessages[siteLanguageDefault][assetInfoKey])}
                                    </Label>
                                    <Input
                                        value={assetInformation}
                                        maxLength={150}
                                        onChange={(e) => {
                                            setAssetInformation(e.value)
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs={6}>
                                    <Label>
                                        {localization.toLanguageString(cityKey, mainMessages[siteLanguageDefault][cityKey])}
                                    </Label>
                                    <Input
                                        value={city}
                                        maxLength={50}
                                        onChange={(e) => {
                                            setCity(e.value)
                                        }}
                                    />
                                </Col>
                                <Col xs={2}>
                                    <Label>
                                        {localization.toLanguageString(stateKey, mainMessages[siteLanguageDefault][stateKey])}
                                    </Label>
                                    <Input
                                        value={state}
                                        maxLength={50}
                                        onChange={(e) => {
                                            setState(e.value)
                                        }}
                                    />
                                </Col>
                                <Col xs={4}>
                                    <Label>
                                        {localization.toLanguageString(countryKey, mainMessages[siteLanguageDefault][countryKey])}
                                    </Label>
                                    <Input
                                        value={country}
                                        maxLength={50}
                                        onChange={(e) => {
                                            setCountry(e.value)
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col className={"flex"}>
                            <div className={"flex flex-grow-1"}>
                                <Label>
                                    {localization.toLanguageString(notesKey, mainMessages[siteLanguageDefault][notesKey])}
                                </Label>
                                <TextArea
                                    className={"flex-grow-1"}
                                    value={notes}
                                    maxLength={500}
                                    onChange={(e) => {
                                        setNotes(e.value)
                                    }}
                                    autoSize={false}
                                />
                            </div>
                        </Col>
                    </Row>
                </>
                break;
            case "MULTI_HOST":
                inputs = <Row>
                    <Col>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.2rem',
                            lineHeight: '2rem'
                        }}>
                            <Label>{toHtml(hostIDLabel)}</Label>
                            <InfoIcon
                                onClick={() => {
                                    setIsVisible(isVisible => ({
                                        ...isVisible,
                                        hostIDHint: !isVisible.hostIDHint,
                                    }));
                                }}
                                size={"medium"}
                                style={{
                                    color: 'var(--keysight-secondary)',
                                    cursor: 'pointer',
                                }}
                            />
                        </div>
                        <KSMPopover
                            show={isVisible.hostIDHint}
                            setShowHandler={() => {
                                setIsVisible(isVisible => ({
                                    ...isVisible,
                                    hostIDHint: false,
                                }));
                            }}
                            anchor={hostIDPopover.current}
                            position={'right'}
                            type={'warning'}
                            style={{maxWidth: 600}}
                            message={
                                <>
                                    <b>
                                        {localization.toLanguageString(hostIdFormattingKey, mainMessages[siteLanguageDefault][hostIdFormattingKey])}
                                    </b>
                                    <br/>
                                    {toHtml(hostIDHint)}
                                </>
                            }
                        />
                        <div ref={hostIDPopover}>
                            <TextArea
                                value={multiHost}
                                rows={10}
                                autoSize={false}
                                onChange={(e) => {
                                    setIsValid(isValid => ({
                                        ...isValid,
                                        multiHost: true
                                    }));
                                    setMultiHost(e.value);
                                }}
                                valid={isValid.multiHost}
                                autoFocus={true}
                            />
                        </div>
                    </Col>
                    <Col/>
                </Row>
                break;
            case "C2V":
            case "HOST_FILE_BIN":
                let extension = hostModal === "C2V" ? ".c2v"
                    : hostModal === "HOST_FILE_BIN" ? ".bin"
                        : ""
                inputs = <Row>
                    <Col>
                        <KSMPopover
                            show={isVisible.hostIDHint}
                            setShowHandler={() => {
                                setIsVisible(isVisible => ({
                                    ...isVisible,
                                    hostIDHint: false,
                                }));
                            }}
                            anchor={hostFilePopoverAnchor.current}
                            position={'right'}
                            type={'warning'}
                            style={{maxWidth: 400}}
                            message={
                                <>
                                    <b>
                                        {localization.toLanguageString(specificHostFileKey, mainMessages[siteLanguageDefault][specificHostFileKey])}
                                    </b>
                                    <br/>
                                    {toHtml(hostIDHint)}
                                </>
                            }
                        />
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.2rem',
                            lineHeight: '2rem'
                        }}>
                            <Label>{localization.toLanguageString(uploadFileKey, mainMessages[siteLanguageDefault][uploadFileKey])}</Label>
                            <InfoIcon
                                onClick={() => {
                                    setIsVisible(isVisible => ({
                                        ...isVisible,
                                        hostIDHint: !isVisible.hostIDHint
                                    }));
                                }}
                                size={"medium"}
                                style={{
                                    color: 'var(--keysight-secondary)',
                                    cursor: 'pointer',
                                }}
                            />
                            <div ref={hostFilePopoverAnchor}/>
                        </div>

                        <div>
                            <Upload
                                className="ksm-upload"
                                batch={false}
                                multiple={false}
                                withCredentials={false}
                                defaultFiles={[]}
                                restrictions={{
                                    allowedExtensions: [extension]
                                }}
                                saveUrl={config.utilities.health}
                                onAdd={onHostFileUpload}
                                selectMessageUI={() =>
                                    <Button
                                        ref={hostFileButtonAnchor}
                                        themeColor={"primary"}
                                        size={"large"}
                                        fillMode={"solid"}
                                        type={"button"}
                                    >
                                        {hostIDLabel}
                                    </Button>
                                }
                            />
                        </div>
                    </Col>
                </Row>
                break;
            case "PARTIAL":
                inputs = <Row>
                    <div
                        className={'k-h4'}
                    >
                        {localization.toLanguageString(specialHandlingKey, mainMessages[siteLanguageDefault][specialHandlingKey])}
                    </div>
                    <div
                        style={{
                            color: "black",
                            fontSize: "1rem"
                        }}
                    >
                        {toHtml(localization.toLanguageString(keysightGenerateLicensesV2Key, mainMessages[siteLanguageDefault][keysightGenerateLicensesV2Key]))}
                    </div>
                </Row>
                break;
            default:
                inputs = <></>
        }

        return (
            <Dialog
                className={"assign-products-modal ksm-dialog"}
                title={<h2 style={titleStyle}>
                    {localization.toLanguageString(assignHostKey, mainMessages[siteLanguageDefault][assignHostKey])}
                </h2>}
                onClose={closeAssignHost}
                width={"64.625rem"}
                height={"90vh"}
            >
                {inputs}
                <Row className="mt-3">
                    <Col>
                        <Grid
                            className="assign-products-grid"
                            scrollable={(hostModal === "MULTI_HOST" && selectedProducts.length >= 5) || (selectedProducts.length >= 10) ? "scrollable" : "none"}
                            data={selectedProducts.filter(product => product.selected)}
                            style={(hostModal === "MULTI_HOST" && selectedProducts.length >= 5) ? {maxHeight: "25vh"} : (selectedProducts.length >= 10) ? {maxHeight: "40vh"} : {}}
                        >
                            <GridColumn
                                field="prod_num_display"
                                title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                                editable={false}
                                cell={NoWrapCell}
                            />
                            <GridColumn
                                field="description"
                                title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                                editable={false}
                            />
                            <GridColumn
                                field="selected_license_type_text"
                                title={localization.toLanguageString(licenseTypeKey, mainMessages[siteLanguageDefault][licenseTypeKey])}
                                columnMenu={ColumnMenu}
                                cell={NoWrapCell}
                            />
                            <GridColumn
                                width={"50px"}
                                cell={TextAlignMiddleCell}
                                field="assign_quantity"
                                title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                                editable={false}
                            />
                        </Grid>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {errors.length ? (errors.map((error, index) => {
                                let field = error.field;
                                let title = error.title;
                                let message = error.message;

                                let errorTitleKey = field === "hostID" ? invalidHostKey
                                    : field === "serialNumber" ? invalidSerialNumberKey
                                        : field === "multi" ? invalidHostKey
                                            : field === "file" ? invalidFileKey
                                                : field === "database" ? genericErrorTitleKey
                                                    : genericErrorTitleKey

                                let errorMultilingualTitle = localization.toLanguageString(
                                    errorTitleKey,
                                    mainMessages[siteLanguageDefault][errorTitleKey]
                                );

                                let errorTitle = (field === "onePerHost" || field === "duplicateHost" || field === "qtyDistribution") ? title
                                    : field === "alias" ? localization.toLanguageString(duplicateAliasKey, mainMessages[siteLanguageDefault][duplicateAliasKey])
                                        : field === "quantityConsumed" ? localization.toLanguageString(quantityConsumedKey, mainMessages[siteLanguageDefault][quantityConsumedKey])
                                            : errorMultilingualTitle

                                let errorMessage = <>{toHtml(message)}</>;

                                return (
                                    <div key={'error-' + index}>
                                        <br/>
                                        <Alert
                                            type={'error'}
                                            title={errorTitle}
                                            message={field === "regex" ? message : errorMessage}
                                        />
                                    </div>
                                )
                            }))
                            :
                            <></>
                        }
                    </Col>
                </Row>
                <DialogActionsBar layout="center">
                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        fillMode={"outline"}
                        type={"button"}
                        onClick={closeAssignHost}
                    >
                        {localization.toLanguageString(cancelChangesKey, mainMessages[siteLanguageDefault][cancelChangesKey])}
                    </Button>
                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        fillMode={"solid"}
                        type={"button"}
                        disabled={isLoading}
                        onClick={() => {
                            validate()
                        }}
                    >
                        {hostModal === "PARTIAL" ? localization.toLanguageString(confirmKey, mainMessages[siteLanguageDefault][confirmKey]) : localization.toLanguageString(saveHostIDKey, mainMessages[siteLanguageDefault][saveHostIDKey])}
                    </Button>
                </DialogActionsBar>
            </Dialog>
        )
    }

    return (
        <>
            {isVisible.modal && AssignHostModal()}
        </>
    )
}

export default AssignProductsModal;